import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110')
];

export const server_loads = [];

export const dictionary = {
		"/(marketing)": [35,[4]],
		"/(dash)/apps/[workspace]/[environment]": [21,[2],[3]],
		"/(dash)/apps/[workspace]/[environment]/deployed/[name]": [23,[2],[3]],
		"/(dash)/apps/[workspace]/[environment]/[appId]": [22,[2],[3]],
		"/(marketing)/articles": [36,[4]],
		"/(signin)/authorize": [51],
		"/(marketing)/blog": [37,[4,5]],
		"/(marketing)/blog/[slug]": [38,[4,5]],
		"/careers": [60],
		"/(marketing)/company": [39,[4]],
		"/(signin)/credit-card-gate": [52],
		"/(marketing)/customers": [40,[4]],
		"/docs": [61,[7],[8]],
		"/docs/examples": [63,[7,9],[8]],
		"/docs/examples/[...id]": [64,[7,9],[8]],
		"/docs/guide/[...id]": [65,[7,10],[8]],
		"/docs/reference/changelog": [67,[7,11],[8]],
		"/docs/reference/cli/[...id]": [68,[7,11],[8]],
		"/docs/reference/[...id]": [66,[7,11],[8]],
		"/docs/[...fallback]": [62,[7],[8]],
		"/examples": [69],
		"/home": [70],
		"/integrations/[slug]/configure": [71],
		"/internal": [72,[12]],
		"/internal/accounts": [73,[12,13]],
		"/internal/accounts/[workspace]": [74,[12,13]],
		"/internal/batch": [75,[12]],
		"/internal/design": [76,[12,14]],
		"/internal/design/components": [77,[12,14]],
		"/internal/design/graphics": [78,[12,14]],
		"/internal/design/typography": [79,[12,14]],
		"/internal/logs": [80,[12]],
		"/internal/lookup": [81,[12,15]],
		"/internal/lookup/[id]": [82,[12,15]],
		"/internal/queues": [83,[12]],
		"/internal/resources": [84,[12]],
		"/internal/resources/fullscreen": [85],
		"/internal/solver": [86,[12]],
		"/internal/users": [87,[12]],
		"/internal/users/[userId]": [88,[12]],
		"/internal/workers": [89,[12]],
		"/(marketing)/legal/privacy-policy": [41,[4]],
		"/(marketing)/legal/terms": [42,[4]],
		"/(signin)/login": [53],
		"/(signin)/login/sso": [54],
		"/(dash)/logs/[workspace]/[environment]": [24,[2],[3]],
		"/(dash)/logs/[workspace]/[environment]/call/[functionCallId]": [26,[2],[3]],
		"/(dash)/logs/[workspace]/[environment]/[appId]": [25,[2],[3]],
		"/playground": [90,[16]],
		"/playground/[slug]": [91,[16]],
		"/(marketing)/pricing": [43,[4]],
		"/(dash)/sandboxes/[workspace]/[environment]": [27,[2],[3]],
		"/(dash)/secrets/[workspace]/[environment]": [28,[2],[3]],
		"/(dash)/secrets/[workspace]/[environment]/create": [29,[2],[3]],
		"/(dash)/secrets/[workspace]/[environment]/edit/[name]": [30,[2],[3]],
		"/(dash)/secrets/[workspace]/[environment]/usage/[name]": [31,[2],[3]],
		"/settings": [92,[17]],
		"/settings/[[workspace]]/audit-logs": [93,[17,18]],
		"/settings/[[workspace]]/domains": [94,[17,18]],
		"/settings/[[workspace]]/image-config": [95,[17,18]],
		"/settings/[[workspace]]/member-tokens": [96,[17,18]],
		"/settings/[[workspace]]/members": [97,[17,18]],
		"/settings/[[workspace]]/metrics": [98,[17,18]],
		"/settings/notifications": [105,[17]],
		"/settings/[[workspace]]/plans": [99,[17,18]],
		"/settings/profile": [106,[17]],
		"/settings/[[workspace]]/proxy": [100,[17,18]],
		"/settings/[[workspace]]/slack-integration": [101,[17,18]],
		"/settings/[[workspace]]/tokens": [102,[17,18]],
		"/settings/[[workspace]]/usage": [103,[17,18]],
		"/settings/[[workspace]]/workspace-management": [104,[17,18]],
		"/settings/workspaces": [107,[17]],
		"/(signin)/signup": [55],
		"/(dash)/slack-link-workspace": [32,[2],[3]],
		"/slack": [108],
		"/startups": [109],
		"/(dash)/storage/[workspace]/[environment]": [33,[2],[3]],
		"/(dash)/storage/[workspace]/[environment]/[name]/[...dirName]": [34,[2],[3]],
		"/token-flow/[tokenFlowId]": [110,[19]],
		"/(marketing)/use-cases": [44,[4,6]],
		"/(marketing)/use-cases/audio": [45,[4,6]],
		"/(marketing)/use-cases/fine-tuning": [46,[4,6]],
		"/(marketing)/use-cases/image-video-3d": [47,[4,6]],
		"/(marketing)/use-cases/job-queues": [48,[4,6]],
		"/(marketing)/use-cases/language-models": [49,[4,6]],
		"/(marketing)/use-cases/sandboxes": [50,[4,6]],
		"/[workspace=workspace]/[environment]/apps/[...rest]": [56],
		"/[workspace=workspace]/[environment]/logs/[...rest]": [57],
		"/[workspace=workspace]/[environment]/secrets/[...rest]": [58],
		"/[workspace=workspace]/[environment]/storage/[...rest]": [59],
		"/(dash)/[fallback=dash]/[...rest]": [20,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';